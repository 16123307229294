import { defineStore } from 'pinia';

export const useGamesStore = defineStore('gamesStore', () => {
  const supportedCurrencies = ref(null);
  const gameServerUrl = ref(null);
  const assetUrl = ref(null);

  const availableCurrencySVGs = [
    'BCH',
    'BNB',
    'BNK1K',
    'BTC',
    'DAI',
    'DOGE',
    'EOS',
    'ETH',
    'EURC',
    'LTC',
    'MATIC',
    'PP1K',
    'RLB',
    'SHB1K',
    'SOL',
    'TRX',
    'USDC',
    'USDT',
    'WIF',
    'XRP',
  ];

  function $reset() {
    supportedCurrencies.value = null;
  }

  return {
    gameServerUrl,
    supportedCurrencies,
    assetUrl,
    $reset,
    availableCurrencySVGs,
  };
});
